import { useShoppingCart } from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import { pf } from "../utilities/helpers";
import React from "react";

const languageTranslations = {
  et: {
    products: "TOOTED",
    total: "KOKKU",
    sum: "Summa",
    discount: "Kohandus",
    delivery: "Tarne",
    paymentComplete: "Makse õnnestus!",
  },
  en: {
    products: "PRODUCTS",
    total: "TOTAL",
    sum: "Sum",
    discount: "Adjustment",
    delivery: "Delivery",
    paymentComplete: "Payment complete!",
  },
};

export default function CheckoutProductsPanelConfirmed() {
  const {
    locationProducts,
    cartItemsConfirmed,
    orderDeliveryMethod,
    totalCartDiscount,
  } = useShoppingCart();

  const lang = usePageLanguage();

  const totalSum = cartItemsConfirmed.reduce((total, cartItem: any) => {
    const item = locationProducts.find((i) => i.id === cartItem.product_id);
    return total + (item?.price_total || 0) * cartItem.quantity;
  }, 0);

  const deliveryPrice =
    orderDeliveryMethod && orderDeliveryMethod.price
      ? Number(orderDeliveryMethod.price)
      : 0;

  return (
    <div className="panel" id="checkoutProductsPanel">
      <div className="panel-heading">
        <div className="checkoutProductsPanelConfirmedHeading">
          <span>{languageTranslations[lang]?.paymentComplete}</span>
          <span>
            <svg
              style={{
                display: "block",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              width="24px"
              height="24px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16.2l-3.5-3.5L4 14l5 5 12-12-1.4-1.4z" />
            </svg>
          </span>
        </div>
        <div className="panel-title">
          <span className="badge badge-square badge-primary">1</span>
          <span className="panel-title-text">
            {languageTranslations[lang]?.products}
          </span>
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-xs-12 padding-top-20">
            <div className="table tablerow-no-border cartTable">
              <div>
                {cartItemsConfirmed.map((item: any) => {
                  const locationProduct = locationProducts.find(
                    (lp) => lp.id === item.product_id
                  );
                  if (locationProduct == null) return null;
                  return (
                    <div key={item.id} className="cartOrderProductRow">
                      <div className="cartOrderProductRowImage d-none d-sm-table-cell">
                        <img
                          style={{ width: "70px" }}
                          src={locationProduct.firstFileUrl}
                          className="cartSidepanelProductImage"
                        />
                      </div>
                      <div className="cartOrderProductRowName">
                        <span>
                          {locationProduct?.translations?.[lang].name}
                        </span>
                      </div>
                      <div className="cartOrderProductQuantity">
                        <div>
                          <span>{item.quantity}</span>

                          <span>{locationProduct.unit_shortname} </span>
                        </div>
                      </div>
                      <div
                        className="cartOrderProductRowPrice"
                        // Span 2 colomns
                        style={{}}
                      >
                        <span style={{ width: "100%" }}>
                          {pf(locationProduct.price_total * item.quantity)}{" "}
                          &euro;
                        </span>
                      </div>
                      <div className="cartOrderProductRowDelete"></div>

                      <div className="cartOrderProductRowDiscount">
                        {locationProduct.adjustment_amount &&
                        locationProduct.adjustments?.length > 0 ? (
                          <span
                            className="discount-price-color"
                            style={{ width: "100%" }}
                          >
                            {locationProduct.adjustments?.map(
                              (adjustment, i) => {
                                const string = `${i > 0 ? ", " : ""}${
                                  adjustment.display_name
                                } ${
                                  adjustment.value.toString().startsWith("-")
                                    ? ""
                                    : "+"
                                } ${adjustment.value}%`;

                                return string;
                              }
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="cardOrderProductRowDiscountPrice ">
                        {locationProduct.adjustment_amount &&
                        locationProduct.adjustments?.length > 0 ? (
                          <span className="discount-price-color">
                            {pf(
                              Number(locationProduct.price_total) *
                                item.quantity
                            )}{" "}
                            &euro;
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-xs-12 padding-top-20">
            <div style={{ fontWeight: "400", fontSize: "14px" }}>
              {languageTranslations[lang]?.sum}
              <span className="pull-right">
                <span className="cartTotal">{pf(totalSum)}</span> &euro;
              </span>
            </div>
          </div>

          {totalCartDiscount > 0 && (
            <div className="col-xs-12 ">
              <div
                className="discount-price-color"
                style={{ fontWeight: "400", fontSize: "14px" }}
              >
                {languageTranslations[lang]?.discount}
                <span className="pull-right">
                  <span className="cartTotal">-{pf(totalCartDiscount)}</span>{" "}
                  &euro;
                </span>
              </div>
            </div>
          )}
          <div className="col-xs-12 ">
            <div style={{ fontWeight: "400", fontSize: "14px" }}>
              {languageTranslations[lang]?.delivery}
              <span className="pull-right">
                <span className="cartTotal">
                  {orderDeliveryMethod ? pf(deliveryPrice) : pf(0)}
                </span>{" "}
                &euro;
              </span>
            </div>
          </div>
          <div className="col-xs-12 ">
            <div style={{ fontWeight: "400", fontSize: "18px" }}>
              {languageTranslations[lang]?.total}
              <span className="pull-right">
                <span className="cartTotal">
                  {pf(totalSum + (orderDeliveryMethod ? deliveryPrice : 0))}
                </span>{" "}
                &euro;
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
