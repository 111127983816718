/* eslint-disable */
import { useParams, useNavigate } from "react-router-dom";
import ProductPanel from "./ProductPanel";
import ProductBreadcrumbs from "./ProductBreadcrumbs";
import "./ProductDetailComponent.css";
import {
  useShoppingCart,
  LocationProduct,
} from "../context/ShoppingCartContext";
import { useAppSelector } from "../../../store/hooks";
import { ProductDetailComponent as ProductDetailComponent_ } from "../interfaces/base";
import { logErrorToBackend } from "../utilities/helpers";
import styled from "@emotion/styled";
import ProductMetadata from "./ProductMetadata";
import RenderChildren from "../RenderChildren";
import usePageLanguage from "../hooks/usePageLanguage";
import React, { useEffect } from "react";
import { LocalMovies } from "@mui/icons-material";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

declare var API: any;

export default function ProductDetailComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: ProductDetailComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));

  const { locationProducts, setLocationProducts, productsLoaded } =
    useShoppingCart();
  const navigate = useNavigate();
  var param_id = useParams().id;
  const locale = usePageLanguage();

  if (window.location.pathname.includes("/builder/")) {
    // use first product if editor
    param_id = "";
  }

  const isBuilder = document.getElementById("BuilderContent");
  const lang = usePageLanguage();

  var locationProduct: LocationProduct | any = [];
  if (locationProducts) {
    if (param_id) {
      locationProduct = locationProducts.find((lp: LocationProduct) => {
        return lp.id == Number(param_id);
      });
      if (!locationProduct) {
        locationProduct = locationProducts.find((lp: LocationProduct) => {
          return lp.translations[lang].product_url == param_id;
        });
      } else if (locationProduct.translations[lang]?.product_url) {
        // change url if found by id
        let stateObj = { id: "100" };
        window.history.pushState(
          stateObj,
          document.title,
          locationProduct.translations[lang].product_url
        );
      }
    } else {
      locationProduct = locationProducts.length ? locationProducts[0] : null;
    }
  }

  useEffect(() => {
    // Always run this hook:

    // If we have a valid locationProduct, push dataLayer info:
    if (locationProduct) {
      console.log(locationProduct, "THIS IS THE LOCATION PRODUCT");

      const locationProductName = {};
      let categories: { category_name: { [k: string]: string } }[] = [];

      Object.entries(locationProduct.translations).forEach(([key, value]) => {
        locationProductName[key] = {
          name: (value as { display_name: string }).display_name,
        };
      });

      locationProduct.productCategories.forEach((category) => {
        const formattedCategory = {
          category_name: Object.fromEntries(
            Object.entries(category.locale_translations).map(
              ([locale, translation]) => [locale, translation as string]
            )
          ),
        };
        categories.push(formattedCategory);
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          products_amount: Number(locationProduct.price_total)
            ? Number(locationProduct.price_total)
            : Number(locationProduct.price_total),

          products_amount_net: Number(locationProduct.price_total_net)
            ? Number(locationProduct.price_total_net)
            : Number(locationProduct.price_total_net),

          items: [
            {
              item_code: locationProduct.id,
              item_name: locationProductName,
              categories: categories,
              price_regular: locationProduct.price_regular,
              discount_amount: Number(
                locationProduct.regular - locationProduct.price_total
              ),
              quantity: 1,
            },
          ],
        },
      });
    }

    // If products are loaded and no locationProduct exists, handle error navigation:
    if (productsLoaded && !locationProduct) {
      const productNotFoundData = {
        name: "ProductNotFound",
        message: "Product with id " + param_id + " was not found in list.",
        stack:
          "Product with id " +
          param_id +
          " was not found in list. In ProductDetailComponent.tsx",
        componentStack:
          "Product with id " +
          param_id +
          " was not found in list. In ProductDetailComponent.tsx",
        href: window.location.href,
      };
      logErrorToBackend(productNotFoundData);
      if (!isBuilder) {
        navigate("/notFoundProduct/" + param_id);
      }
    }
  }, [locationProduct, productsLoaded, param_id, isBuilder, navigate]);

  return (
    <Div
      id={id.toString()}
      key={id}
      data-style={attributes && attributes.styles}
      className={
        attributes &&
        attributes.classes +
          (attributes.classes != "container" &&
          attributes.classes != "container-fluid"
            ? " builder-component builder-component__product_detail"
            : "") +
          (editorId === id ? " builder-component--active" : "")
      }
      data-component-type="section"
      style={{ height: "100%" }}
    >
      <ProductMetadata locationProduct={locationProduct} />
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />

      <div className="page light-gray-background-2 animsition withoutsidemenu product-page">
        {locationProduct && (
          <ProductBreadcrumbs locationProduct={locationProduct} />
        )}
        {locationProduct && (
          <div className="page-content productsResults productPage">
            <ProductPanel locationProduct={locationProduct} id={id} />
          </div>
        )}
      </div>
    </Div>
  );
}
