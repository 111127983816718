import React, { useEffect, useState } from "react";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
import { useAppSelector } from "../../store/hooks";

import "./AccordionComponent.css";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function IframeComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();
  const colors = useAppSelector((state) => state.builder.colors);

  const isBuilder = document.getElementById("BuilderContent");

  const [borderColors, setBorderColors] = useState<any>();

  useEffect(() => {
    const backgroundColor = component.attributes?.color_id
      ? colors?.find((x: any) => x.id === component.attributes?.color_id)?.color
      : component.attributes?.styles.backgroundColor
      ? component.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = component.attributes?.hover_color_id
      ? colors?.find((x: any) => x.id === component.attributes?.hover_color_id)
          ?.color
      : component.attributes?.styles[":hover"] &&
        component.attributes?.styles[":hover"].backgroundColor
      ? component.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "none"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    component.attributes,
    component.attributes?.color_id,
    component.attributes?.hover_color_id,
    component.attributes?.styles.backgroundColor,
    component.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  useEffect(() => {
    // Change border color values

    const borderColorValues = {
      borderTopColor: component.attributes?.borderTopColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderTopColorId
          )?.color
        : component.attributes?.styles.borderTopColor
        ? component.attributes?.styles.borderTopColor
        : "",
      borderBottomColor: component.attributes?.borderBottomColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderBottomColorId
          )?.color
        : component.attributes?.styles.borderBottomColor
        ? component.attributes?.styles.borderBottomColor
        : "",
      borderLeftColor: component.attributes?.borderLeftColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderLeftColorId
          )?.color
        : component.attributes?.styles.borderLeftColor
        ? component.attributes?.styles.borderLeftColor
        : "",
      borderRightColor: component.attributes?.borderRightColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderRightColorId
          )?.color
        : component.attributes?.styles.borderRightColor,
    };

    setBorderColors(borderColorValues);
  }, [
    component.attributes,
    component.attributes.styles,
    component.attributes?.borderBottomColorId,
    component.attributes?.borderLeftColorId,
    component.attributes?.borderRightColorId,
    component.attributes?.borderTopColorId,
    colors,
  ]);

  return (
    <>
      <Div
        data-style={{
          ...backgroundStyles,
          backgroundColor: backgroundStyles?.backgroundColor
            ? backgroundStyles?.backgroundColor
            : "none",
        }}
        id={id.toString()}
        data-component-type="iframe"
        className={
          (attributes.classes ?? "") +
          " builder-component builder-component__iframe" +
          (editorId === id ? " builder-component--active" : "")
        }
        style={{
          ...attributes.styles,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations}
        />
        <iframe
          onClick={(e) => {
            if (!isBuilder) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          title={component.attributes.url ? component.attributes.url : "iframe"}
          loading="lazy"
          style={{
            pointerEvents: isBuilder ? "all" : "all",
          }}
          width="100%"
          height="100%"
          src={component.attributes.url}
        ></iframe>
      </Div>
    </>
  );
}
