import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  setStructure,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";

import {
  TextEditorSelectStyle,
  DefaultReactSelectStyle,
} from "../../../ReactSelectStyle/ReactSelectStyle";
import Select from "react-select";

import { Add, Delete, Edit } from "@mui/icons-material";
import { useAppSelector } from "../../../../../store/hooks";
import {
  NavItemComponent,
  Link,
  ButtonComponent,
  IframeComponent,
} from "../../../../_default/interfaces/base";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import Immutable, { Set, Map } from "immutable";
import { Template } from "../../../../../store/reducers/builderReducers/templates/templateApi";
import StructureAPI from "../../../../../store/reducers/builderReducers/structure/structureApi";
import {
  updateActiveEditor,
  updateEditorState,
} from "../../../../../store/reducers/builderReducers/editor/editorActions";
import { Spinner } from "react-bootstrap";
import {
  getUpdatedComponents,
  getUpdatedWebpageComponents,
  logErrorToBackend,
} from "../../../../_default/utilities/helpers";

interface Props {
  id: number;
  triggerResize: () => void;
}

interface DropdownItem {
  target: string;
  text: string;
  type: string;
  url: string;
}

export default function LinkMenu({ id, triggerResize }: Props) {
  console.log("LinkMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as NavItemComponent & ButtonComponent & IframeComponent;
  const webpages = useAppSelector(
    (state) => state.builder.pages.list as Pages["list"]
  );

  const [templateLoading, setTemplateLoading] = useState(false);

  const structure = useAppSelector((state) => state.builder.structure);
  const components = useAppSelector(
    (state) => state.builder.structure.components
  );

  const allTemplates = useAppSelector((state) => state.builder.templates);

  const templates: Set<Map<string, any>> = Immutable.Set(
    useAppSelector((state) => state.builder.templates).map(
      (template: Template) => Immutable.Map(template)
    )
  );

  const pages = useAppSelector((state) => state.builder.pages);

  // Check if templates is not empty and is a valid Immutable.js Set
  if (
    !templates ||
    !templates.forEach ||
    typeof templates.forEach !== "function"
  ) {
    return null; // or handle accordingly if templates is not valid
  }

  // Filter templates based on the "use" property equal to "megaMenu"
  const megaMenuTemplates = templates.filter((template) => {
    if (template.get("use") === "megaMenu")
      return {
        label: template.get("name"),
        value: template.get("name"),
        component: template.get("component"),
      };
  });

  const [dropdownItems, setDropdownItems] = useState<any[] | null>(
    component.attributes.links ?? []
  );

  const [expanded, setExpanded] = useState(true);
  const [webpagesList, setWebpagesList] = useState(
    webpages.map((x) => {
      return { label: x.name, value: x.url, pageId: x.id };
    })
  );

  const languages = useAppSelector((state) => state.builder.languageSettings);

  const groupedOptions: any[] = [];

  let cachedIFrameURLvalue = "";

  if (component.type === "iframe") {
    cachedIFrameURLvalue = component.attributes.url!;
  }

  languages.activeLanguages.map((language) => {
    const filteredWebpages = webpages.filter((page) => {
      return page.language?.language_id === language.language.id;
    });

    groupedOptions.push({
      label: language.language.name,
      options: filteredWebpages
        .map((page) => {
          return { label: page.name, value: page.url, pageId: page.id };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    });
  });

  const [url, setUrl] = useState<null | string>(
    component.attributes.link?.url === "" ? "/" : component.attributes.link?.url
  );
  const [urlPageId, setUrlPageId] = useState<number | null>(
    component.attributes.link?.pageId ?? null
  );
  const [target, setTarget] = useState<null | string>(
    component.attributes.link?.target
  );
  const [type, setType] = useState<null | string>(
    component.attributes.link?.type
  );
  const [buttonType, setButtonType] = useState<null | string>(
    component.attributes.type
  );

  const editor = useAppSelector((state) => state.builder.editor);
  const webpage_id = useAppSelector((state) => state.builder.pages.active);

  const [selectedMegaMenuTopPosition, setSelectedMegaMenuTopPosition] =
    useState<any>(
      component.attributes.link?.top_position
        ? component.attributes.link?.top_position
        : "0"
    );

  const [selectedMegaMenuLeftPosition, setSelectedMegaMenuLeftPosition] =
    useState<any>(
      component.attributes.link?.left_position
        ? component.attributes.link?.left_position
        : "0"
    );

  const [megaMenuActivated, setMegaMenuActivated] = useState<string>(
    component.attributes.link?.activate_type
      ? component.attributes.link?.activate_type
      : "hover"
  );

  const [template, setTemplate] = useState<null | number>(
    component.attributes.link?.template_id
      ? component.attributes.link?.template_id
      : null
  );

  const refType = useRef(type);
  const refUrl = useRef(url);
  const reftarget = useRef(target);
  const refbuttonType = useRef(buttonType);
  const refUrlPageId: any = useRef<number | undefined | null>(urlPageId);
  const refDropdownItems = useRef<DropdownItem[] | null>(dropdownItems);
  const refTemplate = useRef(template);
  const refTopPosition = useRef(selectedMegaMenuTopPosition);
  const refLeftPosition = useRef(selectedMegaMenuLeftPosition);
  const refActivateType = useRef(megaMenuActivated);

  // React.useEffect(() => {
  //   refType.current = type ? type : refType.current;
  //   refUrl.current = url ? url : refUrl.current;
  //   reftarget.current = target ? target : reftarget.current;
  //   refbuttonType.current = buttonType ? buttonType : refbuttonType.current;
  //   refUrlPageId.current = urlPageId ? urlPageId : refUrlPageId.current;
  //   refDropdownItems.current = dropdownItems
  //     ? dropdownItems
  //     : refDropdownItems.current;
  // }, [type, url, target, buttonType, urlPageId, dropdownItems]);

  useEffect(() => {
    refType.current = type ? type : refType.current;
  }, [type]);

  useEffect(() => {
    if (url === "") {
      refUrl.current = "";
    } else if (url !== "") {
      refUrl.current = url;
    } else {
      refUrl.current = refUrl.current;
    }

    //refUrl.current = url !== "" ? url : refUrl.current;
  }, [url]);

  useEffect(() => {
    refTemplate.current = template ? template : refTemplate.current;
  }, [template]);

  useEffect(() => {
    refTopPosition.current = selectedMegaMenuTopPosition
      ? selectedMegaMenuTopPosition
      : refTopPosition.current;
  }, [selectedMegaMenuTopPosition]);

  useEffect(() => {
    refActivateType.current = megaMenuActivated
      ? megaMenuActivated
      : refActivateType.current;
  }, [megaMenuActivated]);

  useEffect(() => {
    refLeftPosition.current = selectedMegaMenuLeftPosition
      ? selectedMegaMenuLeftPosition
      : refLeftPosition.current;
  }, [selectedMegaMenuLeftPosition]);

  useEffect(() => {
    reftarget.current = target ? target : reftarget.current;
  }, [target]);

  useEffect(() => {
    refTopPosition.current = selectedMegaMenuTopPosition
      ? selectedMegaMenuTopPosition
      : refTopPosition.current;
  }, [selectedMegaMenuTopPosition]);

  useEffect(() => {
    refbuttonType.current = buttonType ? buttonType : refbuttonType.current;
  }, [buttonType]);

  useEffect(() => {
    refUrlPageId.current = urlPageId ? urlPageId : refUrlPageId.current;
  }, [urlPageId]);

  useEffect(() => {
    refDropdownItems.current = dropdownItems
      ? dropdownItems
      : refDropdownItems.current;
  }, [dropdownItems]);

  useLayoutEffect(() => {
    return () => closure();
  }, []);

  const closure = () => {
    if (refType.current === "Dropdown") {
      const pageUrl = webpages.find((x) => x.id === refUrlPageId.current)?.url;

      updateStructureComponent(id, "attributes", {
        ...component.attributes,
        links: refDropdownItems.current,
        link: {
          page_id: refUrlPageId.current,
          url: refUrl.current || pageUrl,
          type: refType.current,
          target: reftarget.current,
        },
      });

      triggerResize();
    } else if (refType.current === "megaMenu") {
      const pageUrl = webpages.find((x) => x.id === refUrlPageId.current)?.url;

      const templateId = templates.filter((x) => {
        if (x.get("id") == template) return x;
      });

      updateStructureComponent(id, "attributes", {
        ...component.attributes,
        link: {
          template_id: refTemplate.current,
          pageId: refUrlPageId.current,
          url: refUrl.current || pageUrl,
          type: refType.current,
          target: reftarget.current,
          top_position: refTopPosition.current,
          left_position: refLeftPosition.current,
          activate_type: refActivateType.current,
        },
        ...(component.type === "Button" && { type: refbuttonType.current }),
        template_id: template,
      });

      // StructureAPI.addExistingComponent(id, {
      //   id: refTemplate.current!,
      // })

      // if (templateId.toJS()[0].webpage_component_id) {
      //   StructureAPI.addExistingComponent(id, {
      //     id: templateId?.toJS()[0].webpage_component_id,
      //     parent_id: id ?? null,
      //     sort_order: 0,
      //   }).then((x) => {

      //   });
      // }
    } else {
      if (refType.current || buttonType) {
        const pageUrl = webpages.find(
          (x) => x.id === refUrlPageId.current
        )?.url;

        const templateId = templates.filter((x) => {
          if (x.get("id") == template) return x;
        });

        updateStructureComponent(id, "attributes", {
          ...component.attributes,
          link: {
            template_id: refTemplate.current,
            pageId: refUrlPageId.current,
            url: refUrl.current || pageUrl,
            type: refType.current,
            target: reftarget.current,
            top_position: refTopPosition.current,
            left_position: refLeftPosition.current,
            activate_type: refActivateType.current,
          },
          ...(component.type === "Button" && { type: refbuttonType.current }),
          template_id: template,
        });

        if (structure.components[templateId.toJS()[0]?.webpage_component_id]) {
          return;
        } else if (templateId.toJS()[0]?.webpage_component_id) {
          StructureAPI.addExistingComponent(pages.active!, {
            id: templateId.toJS()[0].webpage_component_id,
            parent_id: id ?? null,
            sort_order: 0,
          }).then((x) => {});

          // pages.list.forEach((page) => {
          //   if (page.page_type === "templates") {
          //     return;
          //   } else {
          //     StructureAPI.addExistingComponent(page.id, {
          //       id: templateId.toJS()[0].webpage_component_id,
          //       parent_id: id ?? null,
          //       sort_order: 0,
          //     }).then((x) => {

          //       return;
          //     });
          //   }
          // });
          // StructureAPI.addExistingComponent(pages.active!, {
          //   id: templateId.toJS()[0].webpage_component_id,
          //   parent_id: id ?? null,
          //   sort_order: 0,
          // }).then((x) => {

          // });
        }

        // if (components.get(id)) {

        // }

        triggerResize();
      }
    }
  };

  useEffect(() => {
    // Trigger the resize
    triggerResize();
  }, []);

  function addDropdownItem() {
    const newItem = {
      target: "_blank",
      text: "",
      type: "Web",
      url: "",
    };

    if (!dropdownItems) {
      setDropdownItems([newItem]);
    } else {
      setDropdownItems([...dropdownItems, newItem]);
    }

    // updateStructureComponent(id, "attributes.links", [
    //   ...dropdownItems,
    //   { url: "www.google.ee", type: "Web", target: "_blank" },
    // ]);
  }

  function updateDropdownItem(
    i: number,
    params: Link & { text: string; pageId?: number }
  ) {
    if (!dropdownItems) return;

    var dropdownItems_ = [...dropdownItems];
    dropdownItems_[i] = { ...dropdownItems_[i], ...params };
    // updateStructureComponent(id, "attributes.links", dropdownItems_);

    setDropdownItems(dropdownItems_);
  }

  function deleteDropdownItem(i: number) {
    if (!dropdownItems) return;

    var dropdownItems_ = [...dropdownItems];
    dropdownItems_.splice(i, 1);
    // updateStructureComponent(id, "attributes.links", dropdownItems_);

    setDropdownItems(dropdownItems_);
  }

  let types = [
    { label: "Page", value: "Page" },
    { label: "Web in", value: "Web" },
    { label: "Web out", value: "Web_out" },
    { label: "None", value: "None" },
    { label: "Anchor", value: "Anchor" },
    { label: "Phone", value: "Phone" },
    { label: "Email", value: "Email" },
    { label: "Dropdown", value: "Dropdown" },
    { label: "MegaMenu", value: "MegaMenu" },
  ];

  // if (component.type === "nav-item") {
  //   types = [
  //     ...types,
  //     { label: "Anchor", value: "Anchor" },
  //     { label: "Phone", value: "Phone" },
  //     { label: "Email", value: "Email" },
  //     { label: "Dropdown", value: "Dropdown" },
  //     { label: "MegaMenu", value: "MegaMenu" },
  //   ];
  // }

  const linkOpen = [
    { label: "Open new tab", value: "_blank" },
    { label: "Open same tab", value: "_self" },
  ];

  const megaMenuTemplatesList: { label: any; value: any; id: any }[] =
    megaMenuTemplates
      .toJS()
      .map((x) => {
        if (x.use === "megaMenu") {
          return {
            label: x.name,
            value: x.name,
            id: x.id,
            component: x.component,
            ...x,
          };
        }
        // Provide a default object when the condition is not met
        return { label: "", value: "", id: null };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

  // const megaMenuTemplates = [
  //   templates.map((x) => {
  //     if (x.use === "megaMenu") {
  //       return { label: x.name, value: x.name };
  //     } else {
  //       return;
  //     }
  //   }),
  // ];

  // const MegaMenuTemplates = [
  //   templates.map((x) => {
  //     if (x.use === "megaMenu") {
  //       return { label: x.name, value: x.name };
  //     } else {
  //       return;
  //     }
  //   }),
  // ];

  let widthTypes = [
    {
      value: "px",
      label: "px",
    },
  ];

  let activateMenu = [
    {
      label: "hover",
      value: "hover",
    },
    {
      label: "click",
      value: "click",
    },
  ];

  const buttonTypes = [
    { label: "Button", value: "button" },
    { label: "Submit", value: "submit" },
  ];

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    margin: "0px",
  };

  async function setTemplateActive(e: any, template_: Template) {
    e.stopPropagation();
    setTemplateLoading(true);
    // Set the editor.component_id to the template_.component.id

    if (
      editor.template &&
      editor.template.id !== template_.id &&
      editor.template.sort_order === -44
    ) {
      // Delete the component
      StructureAPI.deleteComponent(
        editor.template.webpage_id,
        editor.template
      ).then((x) => {
        setStructure(x.payload);
      });
    }

    const templateComponent = components[template_.webpage_component_id!];

    if (!templateComponent) {
      let updatedComponents;

      let updatedComponentsArray: any = [];
      let updatedWebpageComponentsArray: any = [];

      StructureAPI.loadWebpageComponents(Number(webpage_id!))
        .then((result) => {
          updatedComponentsArray = getUpdatedComponents(result, structure);
          if (structure && structure.webpageComponents) {
            try {
              updatedWebpageComponentsArray = getUpdatedWebpageComponents(
                result.webpageComponents,
                structure.webpageComponents
              );
            } catch (error) {
              console.error("Error in getUpdatedWebpageComponents:", error);

              const errorUpdatedWebpageComponents = {
                name: "getUpdateWebpageComponents",
                message: error,
                stack:
                  "Error in getUpdatedWebpageComponents Editor.tsx " + error,
                componentStack: location.pathname,
                href: window.location.href,
              };
              logErrorToBackend(errorUpdatedWebpageComponents);

              updateEditorState({
                hasEdited: false,
              });
              return;
            }
          } else {
            console.log("No webpageComponents found in current structure");
          }
        })
        .then(() => {
          updatedComponents = {
            webpageComponents: updatedWebpageComponentsArray,
            components: {},
          };

          updatedComponentsArray.forEach((component) => {
            updatedComponents.components[component.id] = {
              ...component,
            };
          });
        })
        .finally(() => {
          StructureAPI.updateWebpageComponents(
            webpage_id,
            updatedComponents
          ).then(() => {
            StructureAPI.addExistingComponent(pages.active!, {
              id: template_.webpage_component_id,
              parent_id: null,
              sort_order: -44,
            }).then((x) => {
              const createdComponent =
                x.payload.components[template_.webpage_component_id];

              const updatedEditor = {
                ...editor,
                component_id: template_.component!.id,
                template: createdComponent,
              };

              setStructure(x.payload);
              setTemplateLoading(false);
              updateActiveEditor(updatedEditor);
            });
          });
        });
    }
    if (templateComponent) {
      if (editor.hasEdited === true) {
        const updatedEditor = {
          ...editor,
          component_id: template_.component!.id,
          template: templateComponent,
        };

        let updatedComponents;

        let updatedComponentsArray: any = [];
        let updatedWebpageComponentsArray: any = [];

        StructureAPI.loadWebpageComponents(Number(webpage_id!))
          .then((result) => {
            updatedComponentsArray = getUpdatedComponents(result, structure);
            if (structure && structure.webpageComponents) {
              try {
                updatedWebpageComponentsArray = getUpdatedWebpageComponents(
                  result.webpageComponents,
                  structure.webpageComponents
                );
              } catch (error) {
                console.error("Error in getUpdatedWebpageComponents:", error);

                const errorUpdatedWebpageComponents = {
                  name: "getUpdateWebpageComponents",
                  message: error,
                  stack:
                    "Error in getUpdatedWebpageComponents Editor.tsx " + error,
                  componentStack: location.pathname,
                  href: window.location.href,
                };
                logErrorToBackend(errorUpdatedWebpageComponents);

                updateEditorState({
                  hasEdited: false,
                });
                return;
              }
            } else {
              console.log("No webpageComponents found in current structure");
            }
          })
          .then(() => {
            updatedComponents = {
              webpageComponents: updatedWebpageComponentsArray,
              components: {},
            };

            updatedComponentsArray.forEach((component) => {
              updatedComponents.components[component.id] = {
                ...component,
              };
            });
          })
          .finally(() => {
            StructureAPI.updateWebpageComponents(
              webpage_id,
              updatedComponents
            ).then(() => {
              updateActiveEditor(updatedEditor);
              setTemplateLoading(false);
            });
          });
      } else if (editor.hasEdited === false || !editor.hasEdited) {
        const updatedEditor = {
          ...editor,
          component_id: template_.component!.id,
          template: templateComponent,
        };
        setTemplateLoading(false);
        updateActiveEditor(updatedEditor);
      }
    }

    // Update the editor

    // if (inClass("dropdown-trigger", e.target)) return;
    // if (template_.component!.id === editorComponent?.parent_id) {
    //   return;
    // } else if (pages.list.find((x) => x.id === template_.webpages[0])) {
    //   if (!template_.webpages.includes(pages.active!)) {
    //     setActivePage(
    //       pages.list.find((x) => x.id === template_.webpages[0])!.id
    //     );
    //     // Wait for the new components to load
    //     await new Promise((resolve) => setTimeout(resolve, 1000));
    //   }
    //   if (template_.component!.id !== editorComponent?.parent_id) {
    //     if (editorComponent) {
    //       deleteComponentById(editorId);
    //     }
    //     addNewComponent({
    //       id: editorId,
    //       type: "editor",
    //       sort_order: 1,
    //       parent_id: template_.component!.id,
    //       childElements: [],
    //     } as any);
    //     setEditableComponentId(template_.component!.id);
    //   }
    // } else {
    //   setEditTemplate(template_);
    // }
  }

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span style={{ fontSize: "10px", color: "#e7e7e7", fontWeight: "bold" }}>
        {data.label}
      </span>
      <span
        style={{
          fontSize: "10px",
          color: "#e7e7e7",
          fontWeight: "bold",
          backgroundColor: "#2e2e2e",
          padding: "3px 5px",
          borderRadius: "2px",
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  return (
    <div className="b-gap-3">
      <div
        className={"  b-gap-2"}
        style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
      >
        {component.type === "iframe" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span>URL:</span>
            <input
              value={cachedIFrameURLvalue}
              onChange={(e) => {
                if (e.target.value) {
                  cachedIFrameURLvalue = e.target.value;

                  updateStructureComponent(
                    id,
                    "attributes.url",
                    e.target.value
                  );
                } else if (e.target.value === "") {
                  cachedIFrameURLvalue = e.target.value;
                  updateStructureComponent(id, "attributes.url", "");
                }
              }}
              style={{
                background: "transparent",
                border: "none",
                borderBottom: "1px solid white",
                color: "#fff",
              }}
            />
          </div>
        )}

        {component.type === "Button" && (
          <div>
            <Select
              onChange={(e) => {
                setButtonType(e!.value);

                updateStructureComponent(id, "attributes.buttonType", e!.value);

                if (e?.value === "submit") {
                  setType(null);
                  setTarget(null);
                  setUrl(null);
                } else {
                  setType("Page");
                  setTarget("_self");
                }
              }}
              isSearchable={false}
              className="m-0 link-menu_input"
              styles={{
                ...TextEditorSelectStyle,
                control: (base) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }),
                container: (base) => ({
                  ...base,
                  minWidth: "80px",
                }),
              }}
              noOptionsMessage={({}) => "Select type"}
              value={buttonTypes.find((x) => x.value === buttonType)}
              options={buttonTypes}
              placeholder="Select type"
            ></Select>
          </div>
        )}
        {((component.type === "Button" && buttonType == "button") ||
          component.type === "nav-item" ||
          component.type === "image" ||
          component.type === "section" ||
          component.type === "logo") && (
          <Select
            onChange={(e) => {
              setType(e!.value);
              if (e!.value === "Page") {
                setUrl(null);
              } else if (type === "Page") {
                setUrl("");
              } else {
                setUrl(null);
              }
            }}
            isSearchable={false}
            className="m-0 link-menu_input"
            styles={{
              ...TextEditorSelectStyle,
              container: (base) => ({
                ...base,
                minWidth: "80px",
              }),

              control: (base) => ({
                ...base,
                backgroundColor: "transparent",
                border: 0,
                minHeight: "inherit",
                borderRadius: 0,
                boxShadow: "none",
                width: "100%",
                display: "flex",
              }),

              singleValue: (base) => ({
                ...base,
                textAlign: "left",
                color: "#e7e7e7",
              }),
            }}
            noOptionsMessage={({}) => ""}
            value={
              types.find((x) => x.value === type)
                ? types.find((x) => x.value === type)
                : types.find((x) => x.value === "None")
            }
            options={types}
            placeholder=""
          ></Select>
        )}
        {/* {type === "Page" && (
          <Select
            onChange={(e) => {
              setUrlPageId(e!.pageId);
              setUrl(e!.value);
            }}
            isSearchable={false}
            className="m-0 link-menu_input"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={webpagesList.find((x) => x.value === url)}
            options={webpagesList}
            placeholder=""
          ></Select>
        )} */}

        {type === "MegaMenu" && (
          <>
            <div className="link-menu_input">
              <Select
                onChange={(e) => {
                  setTemplate(e!.id);

                  // setUrlPageId(e!.pageId);
                  // setUrl(e!.value);
                }}
                isSearchable={false}
                className="m-0"
                styles={{
                  ...TextEditorSelectStyle,

                  container: (base) => ({
                    ...base,
                    width: "auto",
                  }),

                  option: (styles, data) => ({
                    ...DefaultReactSelectStyle.option!(styles, data),
                    textAlign: "left",

                    padding: "2px 12px",
                  }),
                }}
                noOptionsMessage={({}) => ""}
                value={
                  megaMenuTemplatesList.find((x) => x.id === template)
                    ? megaMenuTemplatesList.find((x) => x.id === template)
                    : megaMenuTemplatesList[0]
                }
                options={megaMenuTemplatesList}
                placeholder=""
                formatGroupLabel={formatGroupLabel}
              ></Select>
              {template && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "uppercase",
                  }}
                  onClick={(e) => {
                    if (template) {
                      const templateComponent = allTemplates.find(
                        (x) => x.id === template
                      );

                      console.log(
                        templateComponent,
                        "THIS IS TEMPLATE COMPONENT"
                      );

                      if (!templateComponent) return;

                      setTemplateActive(e, templateComponent);
                    }
                  }}
                >
                  {templateLoading ? (
                    <Spinner
                      style={{ fontSize: "14px", marginLeft: "5px" }}
                      size="sm"
                    />
                  ) : (
                    <Edit style={{ fontSize: "14px", marginLeft: "5px" }} />
                  )}
                </div>
              )}

              {/* SHow only if template is selected */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              onClick={(e) => {
                const templatesButton = document.getElementById(
                  "toggleTemplatesMenu"
                );

                if (!templatesButton) return;

                templatesButton.click();

                const AddButton = document.getElementById(
                  "templates-add-button"
                );
              }}
            >
              <Add
                style={{
                  fontSize: "15px",
                }}
              />
            </div>

            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <span style={{ marginRight: "5px", marginLeft: "5px" }}>
                Top:
              </span>

              <div style={{ display: "flex", gap: "5px" }}>
                <input
                  type="number"
                  className="global-editor__input builder-input spacing-menu_input"
                  size={1}
                  value={selectedMegaMenuTopPosition}
                  onChange={(ev) => {
                    // if (ev.target.value === "" || ev.target.value === "0") {
                    //   setSelectedWidth("px");
                    //   return;
                    // // }

                    // if (ev.target.value === "0") return;

                    if (ev.target.value === "") {
                      setSelectedMegaMenuTopPosition("");

                      updateStructureComponent(
                        id,
                        "attributes.link.top_position",
                        "0"
                      );

                      updateStructureComponent(
                        id,
                        "attributes.link.top_position_unit",
                        "px"
                      );
                    } else {
                      setSelectedMegaMenuTopPosition(ev.target.value);

                      updateStructureComponent(
                        id,
                        "attributes.link.top_position",
                        ev.target.value
                      );

                      updateStructureComponent(
                        id,
                        "attributes.link.top_position_unit",
                        "px"
                      );
                    }
                  }}
                />

                <Select
                  menuPosition="fixed"
                  className="m-0 button-menu_input"
                  isSearchable={false}
                  styles={TextEditorSelectStyle}
                  noOptionsMessage={({}) => ""}
                  value={widthTypes.filter((x) => x.value === "px")}
                  onChange={(val) => {}}
                  options={widthTypes.filter((x) => x.value === "px")}
                  placeholder=""
                ></Select>
              </div>

              <span style={{ marginRight: "5px", marginLeft: "5px" }}>
                Left:
              </span>

              <div style={{ display: "flex", gap: "5px" }}>
                <input
                  type="number"
                  className="global-editor__input builder-input spacing-menu_input"
                  size={1}
                  // value={selectedThumbnailMediaWidth}
                  value={selectedMegaMenuLeftPosition}
                  onChange={(ev) => {
                    // if (ev.target.value === "" || ev.target.value === "0") {
                    //   setSelectedWidth("px");
                    //   return;

                    // if (ev.target.value === "0") return;

                    if (ev.target.value === "") {
                      setSelectedMegaMenuLeftPosition("");

                      updateStructureComponent(
                        id,
                        "attributes.link.left_position",
                        "0"
                      );

                      updateStructureComponent(
                        id,
                        "attributes.link.left_position_unit",
                        "px"
                      );
                    } else {
                      setSelectedMegaMenuLeftPosition(ev.target.value);

                      updateStructureComponent(
                        id,
                        "attributes.link.left_position",
                        ev.target.value
                      );

                      updateStructureComponent(
                        id,
                        "attributes.link.left_position_unit",
                        "px"
                      );
                    }
                  }}
                />

                <Select
                  menuPosition="fixed"
                  className="m-0 button-menu_input"
                  isSearchable={false}
                  styles={TextEditorSelectStyle}
                  noOptionsMessage={({}) => ""}
                  value={widthTypes.filter((x) => x.value === "px")}
                  onChange={(val) => {}}
                  options={widthTypes.filter((x) => x.value === "px")}
                  placeholder=""
                ></Select>

                {/* <span
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Activate:
                </span>
                <Select
                  onChange={(e) => {
         

                    if (!e) return;

                    setMegaMenuActivated(e.value);

                    updateStructureComponent(
                      id,
                      "attributes.link.activate_type",
                      e.value
                    );

                   
                    // setTemplate(e!.id);
                    // setUrlPageId(e!.pageId);
                    // setUrl(e!.value);
                  }}
                  isSearchable={false}
                  className="m-0 link-menu_input"
                  styles={{
                    ...TextEditorSelectStyle,

                    container: (base) => ({
                      ...base,
                      width: "auto",
                    }),

                    option: (styles, data) => ({
                      ...DefaultReactSelectStyle.option!(styles, data),
                      textAlign: "left",

                      padding: "2px 12px",
                    }),
                  }}
                  noOptionsMessage={({}) => ""}
                  value={activateMenu.find(
                    (x) => x.value === megaMenuActivated
                  )}
                  options={activateMenu}
                  placeholder=""
                  formatGroupLabel={formatGroupLabel}
                ></Select> */}
              </div>
            </div>
          </>
        )}

        {type === "Page" && (
          <Select
            onChange={(e) => {
              setUrlPageId(e!.pageId);
              setUrl(e!.value);
            }}
            isSearchable={false}
            className="m-0 link-menu_input"
            styles={{
              ...TextEditorSelectStyle,

              container: (base) => ({
                ...base,
                width: "100px",
              }),

              option: (styles, data) => ({
                ...DefaultReactSelectStyle.option!(styles, data),
                textAlign: "left",

                padding: "2px 12px",
              }),

              control: (base) => ({
                ...base,
                backgroundColor: "transparent",
                border: 0,
                minHeight: "inherit",
                borderRadius: 0,
                boxShadow: "none",
                width: "100%",
              }),
            }}
            noOptionsMessage={({}) => ""}
            value={groupedOptions.map((x) => {
              if (url === "/") {
                return x.options.find((y) => y.value === "");
              } else {
                return x.options.find((y) => y.value === url);
              }
            })}
            options={groupedOptions}
            placeholder="Select page"
            formatGroupLabel={formatGroupLabel}
          ></Select>
        )}

        {["Anchor", "Phone", "Email"].includes(type!) && (
          <input
            className="global-editor__input builder-input link-menu_input web_input_bg"
            style={{ width: "100px" }}
            size={5}
            placeholder={
              type === "Anchor" ? "#" : type === "Phone" ? "tel:" : "mailto:"
            }
            value={url ?? ""}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setUrl(e.target.value);

              triggerResize();
            }}
          />
        )}

        {["Web", "Web_out"].includes(type!) && (
          <div style={{ width: "100%" }}>
            <input
              className="global-editor__input builder-input link-menu_input web_input_bg"
              style={{ width: "100px" }}
              size={5}
              placeholder={type === "Web" ? "/" : "www.google.ee"}
              value={url ?? ""}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setUrl(e.target.value);

                triggerResize();
              }}
            />
          </div>
        )}

        {/* {type !== "Dropdown" && (
            <MoreHorizOutlined
              style={{ fontSize: "18px" }}
              onClick={() => {
                setExpanded(!expanded);
                triggerResize();
              }}
            />
          )} */}
        {type === "Dropdown" && (
          <div
            className="hover-underline link-menu_input color-white"
            style={{ color: "#fff!important", width: "50px" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addDropdownItem();
            }}
          >
            + Add
          </div>
        )}

        {expanded &&
          type !== "Dropdown" &&
          type !== "Anchor" &&
          type !== "Web" &&
          type !== "None" &&
          type !== "Phone" &&
          type !== "Email" &&
          type !== undefined &&
          type !== "MegaMenu" && (
            <Select
              onChange={(e) => setTarget(e!.value)}
              isSearchable={false}
              className="m-0 link-menu_input"
              styles={TextEditorSelectStyle}
              noOptionsMessage={({}) => ""}
              value={
                linkOpen.find((x) => x.value === target)
                  ? linkOpen.find((x) => x.value === target)
                  : linkOpen[1]
              }
              options={linkOpen}
              placeholder=""
            ></Select>
          )}
      </div>

      {type === "Dropdown" && (
        <div
          style={{
            display: "grid",
            flexDirection: "column",
            width: "410px",
            alignItems: "center",
          }}
        >
          {dropdownItems?.map((item, i) => (
            <div className={"dropdown-parent-grid"} key={i}>
              <Select
                onChange={(e) => {
                  updateDropdownItem(i, {
                    ...item,
                    type: e!.value,
                    url: e!.value === "Page" ? webpagesList[0].value : item.url,
                  });
                }}
                isSearchable={false}
                className="m-0 link-menu_input"
                styles={{
                  ...TextEditorSelectStyle,
                  container: (base) => ({
                    ...base,
                    display: "flex",

                    width: "100%",
                    minWidth: "60px",
                  }),
                  option: (styles, data) => ({
                    ...DefaultReactSelectStyle.option!(styles, data),
                    display: "flex",
                    justifyContent: "space-between",
                  }),

                  control: (base) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }),
                }}
                noOptionsMessage={({}) => ""}
                value={types.find((x) => x.value === item.type)}
                options={types.filter((x) => {
                  if (x.value === "Dropdown") return false;
                  if (x.value === "MegaMenu") return false;

                  return true;
                })}
                placeholder=""
              ></Select>
              <input
                className="global-editor__input  link-menu_input link-menu_input_text"
                placeholder="Label"
                size={5}
                value={item.text}
                onChange={(e) =>
                  updateDropdownItem(i, { ...item, text: e.target.value })
                }
              />
              {["Anchor", "Phone", "Email", "Web", "Web_out"].includes(
                item.type
              ) && (
                <input
                  className="global-editor__input builder-input flex-grow-1 link-menu_input"
                  placeholder={
                    item.type === "Anchor"
                      ? "#"
                      : item.type === "Phone"
                      ? "tel:"
                      : item.type === "Email"
                      ? "mailto:"
                      : item.type === "Web"
                      ? "/"
                      : "www.google.ee"
                  }
                  style={{ width: "100%!important" }}
                  size={5}
                  value={item.url}
                  onChange={(e) =>
                    updateDropdownItem(i, { ...item, url: e.target.value })
                  }
                />
              )}
              {item.type === "Page" && (
                <Select
                  onChange={(e) => {
                    const pageUrl = webpages.find(
                      (x) => x.id === e!.pageId
                    )?.url;

                    updateDropdownItem(i, {
                      ...item,
                      url: pageUrl ? pageUrl : e!.value,
                      pageId: e!.pageId,
                    });
                  }}
                  maxMenuHeight={150}
                  styles={{
                    ...TextEditorSelectStyle,
                    container: (base) => ({
                      ...base,
                      width: "100%",
                      minWidth: "100px",
                    }),
                  }}
                  isSearchable={false}
                  className="m-0 link-menu_input"
                  noOptionsMessage={({}) => ""}
                  value={webpagesList.find((x) => x.value === item.url)}
                  options={webpagesList}
                  placeholder=""
                ></Select>
              )}

              {/* Do not remove, needed for grid */}
              {item.type === "None" && <div></div>}

              {!["Anchor", "None", "Web"].includes(item.type) ? (
                <Select
                  onChange={(e) =>
                    updateDropdownItem(i, { ...item, target: e!.value })
                  }
                  isSearchable={false}
                  className="m-0 link-menu_input"
                  styles={{
                    ...TextEditorSelectStyle,
                    control: (base) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }),
                    container: (base) => ({
                      ...base,
                      width: "100%",
                      minWidth: "60px",
                    }),
                  }}
                  noOptionsMessage={({}) => ""}
                  value={linkOpen.find((x) => x.value === item.target)}
                  options={linkOpen}
                  placeholder=""
                ></Select>
              ) : (
                <div></div>
              )}
              <span
                className="text-end flex-grow-1 pe-1 fw-bold clickable"
                style={{ fontWeight: "13px", minWidth: "20px" }}
                onClick={() => deleteDropdownItem(i)}
              >
                <Delete />
              </span>
            </div>
          ))}
        </div>
      )}

      {/* <div
        className="link-menu_input"
        style={{
          fontSize: "11px",

          color: "white",
          padding: "5px",
          backgroundColor: "green",
        }}
        onClick={() => {
          closure();
        }}
      >
        SAVE
      </div> */}
    </div>
  );
}
