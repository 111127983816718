import CartComponent from "./CartComponent";
import LogoComponent from "./LogoComponent";
import NavComponent from "./NavComponent";
import LanguageComponent from "./LanguageComponent";
import MobileNavigation from "./MobileNavigation";

import {
  HeaderComponent as HeaderComponent_,
  NavComponent as NavComponent_,
  LogoComponent as LogoComponent_,
  CartComponent as CartComponent_,
  LanguageComponent as LanguageComponent_,
  AllComponents,
} from "./interfaces/base";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
import zIndex from "@mui/material/styles/zIndex";
import React from "react";

const Header = styled("header")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);
export default function HeaderComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: HeaderComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const structure = useAppSelector((state) => state.builder.structure);
  const component = useAppSelector(
    (state) => state.builder.structure.components[id] as HeaderComponent_
  );

  const editor = useAppSelector((state) => state.builder.editor);
  const isBuilder = document.getElementById("BuilderContent");

  // let childComponents: AllComponents[] = [];

  // let c;

  // if (childWebpageComponentRelations) {
  //   childWebpageComponentRelations.map((cr) => {
  //     c = structure.components[cr.child_id];
  //     if (c) {
  //       childComponents.push(c);
  //     }
  //   });
  // }

  // const logoComponent = childComponents.find(
  //   (x) => x && x.type === "logo"
  // ) as LogoComponent_;
  // const navComponent = childComponents.find(
  //   (x) => x && x.type === "nav"
  // ) as NavComponent_;
  // const cartComponent = childComponents.find(
  //   (x) => x && x.type === "cart"
  // ) as CartComponent_;

  // const languageComponent = childComponents.find(
  //   (x) => x && x.type === "language-select"
  // ) as LanguageComponent_;
  // TODO: make better

  return (
    <Header
      id={id.toString()}
      data-style={attributes.styles ?? ""}
      data-component-type="header"
      className={
        "builder-component builder-component__header " +
        (attributes.classes ?? "") +
        (editorId === id ? " builder-component--active" : "")
      }
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        zIndex: isBuilder && 2,
        width: "100%",
        top:
          attributes.styles.position === "sticky" && isBuilder ? "0px" : "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",

          width: "100%",
          maxWidth:
            component &&
            component.attributes &&
            component.attributes.headerWidthNumber &&
            component.attributes.headerWidthNumber !== 0
              ? `${component.attributes.headerWidthNumber}${
                  component.attributes.headerWidthUnit
                    ? component.attributes.headerWidthUnit
                    : "px"
                }`
              : "unset",
        }}
      >
        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations}
        />
      </div>
    </Header>
  );
}
