import { useShoppingCart } from "../context/ShoppingCartContext";
import { pf } from "../utilities/helpers";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

type CartItemProps = {
  id: number;
  quantity: number;
  deleteHidden?: boolean;
};

export function CartItemRow({ id, quantity, deleteHidden }: CartItemProps) {
  const {
    locationProducts,
    decreaseCartQuantity,
    setCartQuantity,
    increaseCartQuantity,
    removeFromCart,
  } = useShoppingCart();
  const locationProduct = locationProducts.find((i) => i.id === id);
  const lang = usePageLanguage();

  if (locationProduct == null) return null;

  return (
    <div className="cartOrderProductRow">
      <div
        className="cartOrderProductRowImage "
        style={{
          paddingTop: deleteHidden ? "5px" : "0px",
          paddingRight: deleteHidden ? "5px" : "0px",
        }}
      >
        <img
          style={{
            width: deleteHidden ? "70px" : "80px",
            height: deleteHidden ? "70px" : "80px",
          }}
          src={locationProduct.firstFileUrl}
          className="cartSidepanelProductImage"
        />
      </div>
      <div className="cartOrderProductRowName">
        <span>{locationProduct?.translations?.[lang].name}</span>
      </div>
      <div className="cartOrderProductQuantity">
        <RemoveIcon
          className="icon clickable padding-5 fs-14"
          style={{ marginTop: "4px" }}
          onClick={() => decreaseCartQuantity(id)}
        />
        <div>
          {/* <span>{quantity}</span> */}
          <input
            type="text"
            name="quantity"
            value={quantity}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCartQuantity(id, +e.target.value)
            }
            style={{
              display: "inline-block",
              width: "22px",
              backgroundColor: "transparent",
              border: "none",
              textAlign: "right",
            }}
          />{" "}
          <span>{locationProduct.unit_shortname} </span>
        </div>
        <AddIcon
          style={{ marginTop: "4px" }}
          className="icon clickable padding-5 fs-14 "
          onClick={() => increaseCartQuantity(id)}
        />
      </div>
      <div className="cartOrderProductRowPrice">
        <span style={{ width: "100%" }}>
          {pf(locationProduct.price_regular * quantity)} &euro;
        </span>
      </div>
      <div
        className="cartOrderProductRowDelete"
        style={{
          visibility: deleteHidden ? "hidden" : "visible",
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeFromCart(id)} />
      </div>

      <div className="cartOrderProductRowDiscount">
        {locationProduct.adjustment_amount &&
        locationProduct.adjustments?.length > 0 ? (
          <span className="discount-price-color">
            {locationProduct.adjustments?.map((adjustment, i) => {
              return `${i > 0 ? ", " : ""}${adjustment.display_name} ${
                adjustment.value.toString().startsWith("-") ? "" : "+"
              }${adjustment.value}%`;
            })}
          </span>
        ) : (
          ""
        )}
      </div>

      <div className="cartOrderProductRowPrice">
        {locationProduct.adjustment_amount &&
        locationProduct.adjustments?.length > 0 ? (
          <span
            className="discount-price-color"
            style={{ width: "100%", fontWeight: "500" }}
          >
            {pf(Number(-locationProduct.adjustment_amount) * quantity)} &euro;
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
