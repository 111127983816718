import React, { Suspense, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { ButtonComponent as ButtonComponent_ } from "./interfaces/base";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import RenderChildren from "./RenderChildren";
import BackgroundVideoComponent from "./BackgroundVideoComponent";
import useLazyLoad from "./utilities/useLazyLoad";
import { getPageLocaleUrl } from "./utilities/helpers";
import MegaMenuComponent from "./MegaMenuComponent";
import { EditorContent } from "@tiptap/react";

const Button = styled("button")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

// Mapping of icon names to lazy-loaded components
const iconMapping: Record<
  string,
  React.LazyExoticComponent<React.ComponentType<any>>
> = {
  SwapVert: React.lazy(() => import("@mui/icons-material/SwapVert")),
  Apple: React.lazy(() => import("@mui/icons-material/Apple")),
  InsertEmoticon: React.lazy(
    () => import("@mui/icons-material/InsertEmoticon")
  ),
  Bookmark: React.lazy(() => import("@mui/icons-material/Bookmark")),
  AddBox: React.lazy(() => import("@mui/icons-material/AddBox")),
  Work: React.lazy(() => import("@mui/icons-material/Work")),
  Cake: React.lazy(() => import("@mui/icons-material/Cake")),
  Camera: React.lazy(() => import("@mui/icons-material/Camera")),
  CameraAlt: React.lazy(() => import("@mui/icons-material/CameraAlt")),
  DirectionsCar: React.lazy(() => import("@mui/icons-material/DirectionsCar")),
  Addchart: React.lazy(() => import("@mui/icons-material/Addchart")),
  BarChart: React.lazy(() => import("@mui/icons-material/BarChart")),
  AccountCircle: React.lazy(() => import("@mui/icons-material/AccountCircle")),
  AddCircle: React.lazy(() => import("@mui/icons-material/AddCircle")),
  CheckCircle: React.lazy(() => import("@mui/icons-material/CheckCircle")),
  Circle: React.lazy(() => import("@mui/icons-material/Circle")),
  AddCircleOutline: React.lazy(
    () => import("@mui/icons-material/AddCircleOutline")
  ),
  LockClock: React.lazy(() => import("@mui/icons-material/LockClock")),
  AccessAlarm: React.lazy(() => import("@mui/icons-material/AccessAlarm")),
  Coffee: React.lazy(() => import("@mui/icons-material/Coffee")),
  LocalCafe: React.lazy(() => import("@mui/icons-material/LocalCafe")),
  Diamond: React.lazy(() => import("@mui/icons-material/Diamond")),
  Discount: React.lazy(() => import("@mui/icons-material/Discount")),
  LocalOffer: React.lazy(() => import("@mui/icons-material/LocalOffer")),
  Pets: React.lazy(() => import("@mui/icons-material/Pets")),
  GasMeter: React.lazy(() => import("@mui/icons-material/GasMeter")),
  Egg: React.lazy(() => import("@mui/icons-material/Egg")),
  InvertColors: React.lazy(() => import("@mui/icons-material/InvertColors")),
  AttachFile: React.lazy(() => import("@mui/icons-material/AttachFile")),
  Fingerprint: React.lazy(() => import("@mui/icons-material/Fingerprint")),
  LocalGasStation: React.lazy(
    () => import("@mui/icons-material/LocalGasStation")
  ),
  Hardware: React.lazy(() => import("@mui/icons-material/Hardware")),
  Construction: React.lazy(() => import("@mui/icons-material/Construction")),
  Favorite: React.lazy(() => import("@mui/icons-material/Favorite")),
  FavoriteBorder: React.lazy(
    () => import("@mui/icons-material/FavoriteBorder")
  ),
  Hexagon: React.lazy(() => import("@mui/icons-material/Hexagon")),
  House: React.lazy(() => import("@mui/icons-material/House")),
  EmojiObjects: React.lazy(() => import("@mui/icons-material/EmojiObjects")),
  Light: React.lazy(() => import("@mui/icons-material/Light")),
  Tungsten: React.lazy(() => import("@mui/icons-material/Tungsten")),
  Laptop: React.lazy(() => import("@mui/icons-material/Laptop")),
  Grid3x3: React.lazy(() => import("@mui/icons-material/Grid3x3")),
  AlignHorizontalCenter: React.lazy(
    () => import("@mui/icons-material/AlignHorizontalCenter")
  ),
  Layers: React.lazy(() => import("@mui/icons-material/Layers")),
  Spa: React.lazy(() => import("@mui/icons-material/Spa")),
  Block: React.lazy(() => import("@mui/icons-material/Block")),
  Lock: React.lazy(() => import("@mui/icons-material/Lock")),
  AlternateEmail: React.lazy(
    () => import("@mui/icons-material/AlternateEmail")
  ),
  Email: React.lazy(() => import("@mui/icons-material/Email")),
  ContactMail: React.lazy(() => import("@mui/icons-material/ContactMail")),
  PinDrop: React.lazy(() => import("@mui/icons-material/PinDrop")),
  Monitor: React.lazy(() => import("@mui/icons-material/Monitor")),
  Bedtime: React.lazy(() => import("@mui/icons-material/Bedtime")),
  Mouse: React.lazy(() => import("@mui/icons-material/Mouse")),
  AirplanemodeActive: React.lazy(
    () => import("@mui/icons-material/AirplanemodeActive")
  ),
  Pentagon: React.lazy(() => import("@mui/icons-material/Pentagon")),
  Shield: React.lazy(() => import("@mui/icons-material/Shield")),
  ShoppingCart: React.lazy(() => import("@mui/icons-material/ShoppingCart")),
  Square: React.lazy(() => import("@mui/icons-material/Square")),
  Star: React.lazy(() => import("@mui/icons-material/Star")),
  ChangeHistory: React.lazy(() => import("@mui/icons-material/ChangeHistory")),
  DoDisturb: React.lazy(() => import("@mui/icons-material/DoDisturb")),
  // Add more icons as needed, e.g.:
  // Home: React.lazy(() => import("@mui/icons-material/Home")),
};

type IconComponentProps = {
  id: string | number;
  attributes: any;
  childElements?: any;
  childWebpageComponentRelations?: any;
  // Pass the icon name from the parent so we know which icon to load
  iconName: string;
};

export default function IconComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: IconComponentProps) {
  const editorId = useAppSelector((state) => state.textEditor?.id);
  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);
  const [style, setStyle] = useState<React.CSSProperties>();
  const [hoverStyle, setHoverStyle] = useState<any>();
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const isBuilder = document.getElementById("BuilderContent");
  let editor = useAppSelector((state) => state.builder?.editor?.editor);
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();

  const [iconName, setIconName] = useState("SwapVert");

  const [borderColors, setBorderColors] = useState<any>();

  const builderEditor = useAppSelector((state) => state.builder.editor);

  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );

  const component = useAppSelector(
    (state) => state.builder.structure.components
  ) as any;

  const [backgroundImage, setBackgroundImage] = useState(
    attributes.styles.backgroundImage ? attributes.styles.backgroundImage : ""
  );

  const buttonRefElement = useRef<HTMLButtonElement>(null);
  const loaded = useLazyLoad(buttonRefElement, backgroundImage);

  const pages = useAppSelector((state) => state.builder.pages);
  const [templateEditText, setTemplateEditText] = useState(false);

  useEffect(() => {
    if (attributes.styles.backgroundImage) {
      setBackgroundImage(attributes.styles.backgroundImage);
    }
  }, [attributes.styles.backgroundImage]);

  const handleMegaMenuOpen = (boolean) => {
    if (attributes.link?.type === "MegaMenu") {
      setMegaMenuOpen(boolean);
    }
  };

  const buttonComponent = component[id];

  useEffect(() => {
    if (
      component &&
      buttonComponent &&
      buttonComponent.type === "IconComponent"
    ) {
      if (buttonComponent.attributes.icon) {
        setIconName(buttonComponent.attributes.icon);
      } else {
        setIconName("Apple");
      }

      //setIconName("SwapVert");
    }
  }, [buttonComponent]);

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );
    if (compTypography) {
      setStyle({
        backgroundColor: attributes.styles.backgroundColor ?? "#fff",
        width: attributes.styles.width ?? "auto",

        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        fontStyle: compTypography.font_style,
        fontWeight: compTypography.font_weight,
        letterSpacing: compTypography.letter_spacing,
        lineHeight: attributes.styles.lineHeight ?? compTypography.line_height,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
        transition:
          compTypography.transition_duration +
          " color " +
          compTypography.transition_effect,
      });
      setHoverStyle({
        "&:hover": {
          color:
            colors?.find(
              (x: any) => x.id === compTypography.link_hover_color_id
            )?.color + " !important",
        },
      });
    }
  }, [typographies, attributes]);

  // If builder, do not render submit button, or else cant edit the button while in a form

  let href;

  useEffect(() => {
    const templateContainer = document.getElementById(
      "template_edit_over_lay_content"
    );
    const textElement = buttonRefElement.current;

    if (
      textElement &&
      templateContainer &&
      templateContainer.contains(textElement)
    ) {
      setTemplateEditText(true);
    }
  }, [editorId]);

  useEffect(() => {
    const backgroundColor = buttonComponent.attributes?.color_id
      ? colors?.find((x: any) => x.id === buttonComponent.attributes?.color_id)
          ?.color
      : buttonComponent.attributes?.styles.backgroundColor
      ? buttonComponent.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = buttonComponent.attributes?.hover_color_id
      ? colors?.find(
          (x: any) => x.id === buttonComponent.attributes?.hover_color_id
        )?.color
      : buttonComponent.attributes?.styles[":hover"] &&
        buttonComponent.attributes?.styles[":hover"].backgroundColor
      ? buttonComponent.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "#fff"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    buttonComponent.attributes,
    buttonComponent.attributes?.color_id,
    buttonComponent.attributes?.hover_color_id,
    buttonComponent.attributes?.styles.backgroundColor,
    buttonComponent.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  useEffect(() => {
    // Change border color values

    const borderColorValues = {
      borderTopColor: buttonComponent.attributes?.borderTopColorId
        ? colors?.find(
            (x: any) => x.id === buttonComponent.attributes?.borderTopColorId
          )?.color
        : buttonComponent.attributes?.styles.borderTopColor
        ? buttonComponent.attributes?.styles.borderTopColor
        : "",
      borderBottomColor: buttonComponent.attributes?.borderBottomColorId
        ? colors?.find(
            (x: any) => x.id === buttonComponent.attributes?.borderBottomColorId
          )?.color
        : buttonComponent.attributes?.styles.borderBottomColor
        ? buttonComponent.attributes?.styles.borderBottomColor
        : "",
      borderLeftColor: buttonComponent.attributes?.borderLeftColorId
        ? colors?.find(
            (x: any) => x.id === buttonComponent.attributes?.borderLeftColorId
          )?.color
        : buttonComponent.attributes?.styles.borderLeftColor
        ? buttonComponent.attributes?.styles.borderLeftColor
        : "",
      borderRightColor: buttonComponent.attributes?.borderRightColorId
        ? colors?.find(
            (x: any) => x.id === buttonComponent.attributes?.borderRightColorId
          )?.color
        : buttonComponent.attributes?.styles.borderRightColor,
    };

    setBorderColors(borderColorValues);
  }, [
    buttonComponent.attributes,
    buttonComponent.attributes.styles,
    buttonComponent.attributes?.borderBottomColorId,
    buttonComponent.attributes?.borderLeftColorId,
    buttonComponent.attributes?.borderRightColorId,
    buttonComponent.attributes?.borderTopColorId,
    colors,
  ]);

  // Get the icon component from our mapping based on the passed iconName.
  const Icon = iconMapping[iconName];

  console.log(Icon, "THIS IS THE ICON???");

  return (
    <Button
      ref={buttonRefElement}
      style={
        attributes.link?.type === "Web" ||
        attributes.link?.type === "Page" ||
        attributes.link?.type === "Web_out" ||
        attributes.link?.type === "Anchor"
          ? {
              textDecoration: "none",
              display: component[id].attributes.styles.display
                ? component[id].attributes.styles.display
                : "inline-grid",

              alignItems: "center",
              justifyContent: buttonComponent.attributes.textAlign
                ? buttonComponent.attributes.textAlign
                : "center",
            }
          : null
      }
      id={id.toString()}
      type={attributes.buttonType ? attributes.buttonType : "button"}
      className={
        "builder-component relative builder-component__button " +
        (attributes.classes ?? "") +
        (editorId === id ? " builder-component--active" : "")
      }
      data-style={{
        ...style,
        ...attributes.styles,
        ...hoverStyle,
        minWidth: "5px",

        backgroundImage: !isBuilder
          ? loaded
            ? loaded.map((src) => `url("${src}")`).join(", ")
            : "none"
          : attributes.styles.backgroundImage,

        // Set the border widths for each border side, left right, top and bottom

        borderLeftWidth: attributes.styles.borderLeftWidth
          ? attributes.styles.borderLeftWidth
          : "0px",
        borderRightWidth: attributes.styles.borderRightWidth
          ? attributes.styles.borderRightWidth
          : "0px",
        borderTopWidth: attributes.styles.borderTopWidth
          ? attributes.styles.borderTopWidth
          : "0px",
        borderBottomWidth: attributes.styles.borderBottomWidth
          ? attributes.styles.borderBottomWidth
          : "0px",
        ...backgroundStyles,
        backgroundColor: backgroundStyles?.backgroundColor
          ? backgroundStyles?.backgroundColor
          : "none",

        borderLeftColor: borderColors?.borderLeftColor,
        borderRightColor: borderColors?.borderRightColor,
        borderTopColor: borderColors?.borderTopColor,
        borderBottomColor: borderColors?.borderBottomColor,
        width: "fit",
      }}
      data-component-type="button"
      onClick={(e) => {
        e.stopPropagation();

        if (isBuilder) {
          e.preventDefault();
        }

        handleMegaMenuOpen(!megaMenuOpen);
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        handleMegaMenuOpen(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();

        handleMegaMenuOpen(false);
      }}
      draggable={false}
    >
      <BackgroundVideoComponent id={id} />
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />

      {/* Render the dynamically imported icon */}
      <div
        style={{
          color: attributes.icon_color || "#000000",
        }}
      >
        {Icon && (
          <Suspense fallback={<div>Loading icon...</div>}>
            <Icon
              style={{
                fontSize: attributes.icon_size
                  ? `${attributes.icon_size}${
                      attributes.iconWidthUnit ? attributes.iconWidthUnit : "px"
                    }`
                  : `25${
                      attributes.iconWidthUnit ? attributes.iconWidthUnit : "px"
                    }`,
              }}
            />
          </Suspense>
        )}
      </div>
    </Button>
  );
}
