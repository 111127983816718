import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import "./IconMenu.scss";
import {
  updateComponentStyle,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { ButtonComponent } from "../../../../_default/interfaces/base";
import {
  Apple,
  InsertEmoticon,
  Bookmark,
  AddBox,
  Work,
  Cake,
  Camera,
  CameraAlt,
  DirectionsCar,
  Addchart,
  BarChart,
  AccountCircle,
  AddCircle,
  CheckCircle,
  Circle,
  AddCircleOutline,
  LockClock,
  AccessAlarm,
  Coffee,
  LocalCafe,
  Diamond,
  Discount,
  LocalOffer,
  Pets,
  GasMeter,
  Egg,
  InvertColors,
  AttachFile,
  Fingerprint,
  LocalGasStation,
  Hardware,
  Construction,
  Favorite,
  FavoriteBorder,
  Hexagon,
  House,
  EmojiObjects,
  Light,
  Tungsten,
  Laptop,
  Grid3x3,
  AlignHorizontalCenter,
  Layers,
  Spa,
  Block,
  Lock,
  AlternateEmail,
  Email,
  ContactMail,
  PinDrop,
  Monitor,
  Bedtime,
  Mouse,
  AirplanemodeActive,
  Pentagon,
  Shield,
  ShoppingCart,
  Square,
  Star,
  ChangeHistory,
  DoDisturb,
} from "@mui/icons-material";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";

export default function ButtonMenu({ id, triggerResize }) {
  const [menuOpen, setMenuOpen] = useState("");
  const [iconColor, setIconColor] = useState("");

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as ButtonComponent & any;

  const [selectedIcon, setSelectedIcon] = useState(
    component.attributes.icon || "Apple"
  );

  const getIconStyle = (iconName: string) => ({
    border:
      component.attributes.icon === iconName
        ? "1px solid #48E348"
        : "1px solid transparent",
    background: "#444",
    borderRadius: "3px",
  });

  useEffect(() => {
    setIconColor(component.attributes.icon_color ?? "#000");
  }, [component]);

  useEffect(() => {
    triggerResize();
  }, []);

  const widthUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
            }}
          >
            <span>Icon size</span>

            <input
              style={{
                marginTop: "2px",
              }}
              value={
                // If icon_size is exactly 0, show an empty string,
                // otherwise if it's defined, show that value; otherwise default to 23.
                component.attributes.icon_size === 0
                  ? ""
                  : component.attributes.icon_size || 25
              }
              onChange={(e) => {
                const newValue = e.target.value;
                console.log(newValue, "ICON SIZE");

                // If the input is cleared, update to 0
                if (newValue === "") {
                  updateStructureComponent(id, "attributes.icon_size", 0);
                  return;
                }

                // Otherwise update with the new numeric value (you might also want to parse it as a number)
                updateStructureComponent(
                  id,
                  "attributes.icon_size",
                  Number(newValue)
                );
              }}
              className="global-editor__input builder-input spacing-menu_input"
              type="number"
            />
          </div>

          <div className="border-menu_input" style={{ position: "relative" }}>
            <Select
              menuPosition="fixed"
              isSearchable={false}
              styles={TextEditorSelectStyle}
              noOptionsMessage={({}) => ""}
              value={
                widthUnits.find(
                  (w) => w.value === component.attributes.iconWidthUnit
                ) || widthUnits.find((x) => x.value === "px")
              }
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.iconWidthUnit",
                  val?.value
                );
              }}
              options={widthUnits}
              placeholder=""
            ></Select>
          </div>

          <div
            style={{
              marginTop: "0px",
            }}
            className="b-ms-1 color-picker color-picker__swatch-item color-picker__swatch-item__parent position-relative dropup"
          >
            <div
              className="color-picker color-picker__swatch-item position-relative"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              style={{
                margin: 0,
                backgroundColor: iconColor,
              }}
              onClick={() => {
                if (menuOpen === "background") {
                  setMenuOpen("");
                } else {
                  setMenuOpen("background");
                }
              }}
            ></div>
            {menuOpen === "background" && (
              <ColorPicker
                top={20}
                title="Background"
                value={iconColor ?? "#fff"}
                returnType="hex"
                open={menuOpen === "background"}
                onChange={(val, colorId) => {
                  updateStructureComponent(id, "attributes.icon_color", val);
                }}
                setColorPickerOpen={(val) => setMenuOpen(val)}
              />
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            gap: "10px",
            width: "200px",
            height: "150px",
            overflow: "scroll",
          }}
        >
          <InsertEmoticon
            style={getIconStyle("InsertEmoticon")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "InsertEmoticon")
            }
          />
          <Apple
            style={getIconStyle("Apple")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Apple")
            }
          />
          <Bookmark
            style={getIconStyle("Bookmark")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Bookmark")
            }
          />
          <AddBox
            style={getIconStyle("AddBox")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "AddBox")
            }
          />
          <Work
            style={getIconStyle("Work")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Work")
            }
          />
          <Cake
            style={getIconStyle("Cake")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Cake")
            }
          />
          <Camera
            style={getIconStyle("Camera")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Camera")
            }
          />
          <CameraAlt
            style={getIconStyle("CameraAlt")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "CameraAlt")
            }
          />
          <DirectionsCar
            style={getIconStyle("DirectionsCar")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "DirectionsCar")
            }
          />
          <Addchart
            style={getIconStyle("Addchart")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Addchart")
            }
          />
          <BarChart
            style={getIconStyle("BarChart")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "BarChart")
            }
          />
          <AccountCircle
            style={getIconStyle("AccountCircle")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "AccountCircle")
            }
          />
          <AddCircle
            style={getIconStyle("AddCircle")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "AddCircle")
            }
          />
          <CheckCircle
            style={getIconStyle("CheckCircle")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "CheckCircle")
            }
          />
          <Circle
            style={getIconStyle("Circle")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Circle")
            }
          />
          <AddCircleOutline
            style={getIconStyle("AddCircleOutline")}
            onClick={() =>
              updateStructureComponent(
                id,
                "attributes.icon",
                "AddCircleOutline"
              )
            }
          />
          <LockClock
            style={getIconStyle("LockClock")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "LockClock")
            }
          />
          <AccessAlarm
            style={getIconStyle("AccessAlarm")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "AccessAlarm")
            }
          />
          <Coffee
            style={getIconStyle("Coffee")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Coffee")
            }
          />
          <LocalCafe
            style={getIconStyle("LocalCafe")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "LocalCafe")
            }
          />
          <Diamond
            style={getIconStyle("Diamond")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Diamond")
            }
          />
          <Discount
            style={getIconStyle("Discount")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Discount")
            }
          />
          <LocalOffer
            style={getIconStyle("LocalOffer")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "LocalOffer")
            }
          />
          <Pets
            style={getIconStyle("Pets")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Pets")
            }
          />
          <GasMeter
            style={getIconStyle("GasMeter")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "GasMeter")
            }
          />
          <Egg
            style={getIconStyle("Egg")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Egg")
            }
          />
          <AttachFile
            style={getIconStyle("AttachFile")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "AttachFile")
            }
          />
          <Fingerprint
            style={getIconStyle("Fingerprint")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Fingerprint")
            }
          />
          <LocalGasStation
            style={getIconStyle("LocalGasStation")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "LocalGasStation")
            }
          />
          <Hardware
            style={getIconStyle("Hardware")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Hardware")
            }
          />
          <Construction
            style={getIconStyle("Construction")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Construction")
            }
          />
          <Favorite
            style={getIconStyle("Favorite")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Favorite")
            }
          />
          <FavoriteBorder
            style={getIconStyle("FavoriteBorder")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "FavoriteBorder")
            }
          />
          <Hexagon
            style={getIconStyle("Hexagon")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Hexagon")
            }
          />
          <House
            style={getIconStyle("House")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "House")
            }
          />
          <EmojiObjects
            style={getIconStyle("EmojiObjects")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "EmojiObjects")
            }
          />
          <Light
            style={getIconStyle("Light")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Light")
            }
          />
          <Tungsten
            style={getIconStyle("Tungsten")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Tungsten")
            }
          />
          <Laptop
            style={getIconStyle("Laptop")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Laptop")
            }
          />
          <Layers
            style={getIconStyle("Layers")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Layers")
            }
          />
          <Grid3x3
            style={getIconStyle("Grid3x3")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Grid3x3")
            }
          />
          <AlignHorizontalCenter
            style={getIconStyle("AlignHorizontalCenter")}
            onClick={() =>
              updateStructureComponent(
                id,
                "attributes.icon",
                "AlignHorizontalCenter"
              )
            }
          />
          <Spa
            style={getIconStyle("Spa")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Spa")
            }
          />
          <Block
            style={getIconStyle("Block")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Block")
            }
          />
          <Lock
            style={getIconStyle("Lock")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Lock")
            }
          />
          <AlternateEmail
            style={getIconStyle("AlternateEmail")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "AlternateEmail")
            }
          />
          <Email
            style={getIconStyle("Email")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Email")
            }
          />
          <ContactMail
            style={getIconStyle("ContactMail")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "ContactMail")
            }
          />
          <PinDrop
            style={getIconStyle("PinDrop")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "PinDrop")
            }
          />
          <Monitor
            style={getIconStyle("Monitor")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Monitor")
            }
          />
          <Bedtime
            style={getIconStyle("Bedtime")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Bedtime")
            }
          />
          <Mouse
            style={getIconStyle("Mouse")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Mouse")
            }
          />
          <AirplanemodeActive
            style={getIconStyle("AirplanemodeActive")}
            onClick={() =>
              updateStructureComponent(
                id,
                "attributes.icon",
                "AirplanemodeActive"
              )
            }
          />
          <Pentagon
            style={getIconStyle("Pentagon")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Pentagon")
            }
          />
          <Shield
            style={getIconStyle("Shield")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Shield")
            }
          />
          <ShoppingCart
            style={getIconStyle("ShoppingCart")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "ShoppingCart")
            }
          />
          <Square
            style={getIconStyle("Square")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Square")
            }
          />
          <Star
            style={getIconStyle("Star")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "Star")
            }
          />
          <ChangeHistory
            style={getIconStyle("ChangeHistory")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "ChangeHistory")
            }
          />

          <DoDisturb
            style={getIconStyle("DoDisturb")}
            onClick={() =>
              updateStructureComponent(id, "attributes.icon", "DoDisturb")
            }
          />
        </div>
      </div>
    </div>
  );
}
