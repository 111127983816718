import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import StructureAPI from "../../../store/reducers/builderReducers/structure/structureApi";
import WebsiteApi from "../../../store/reducers/builderReducers/website/websiteApi";
import { NotificationManager } from "react-notifications";

import { useAppSelector } from "../../../store/hooks";
import React from "react";
import { isEqual, cloneDeep } from "lodash";
import { StructureMap } from "../../../store/reducers/builderReducers/structure/structureReducer";
import { updateEditorState } from "../../../store/reducers/builderReducers/editor/editorActions";
import {
  getUpdatedComponents,
  getUpdatedWebpageComponents,
  logErrorToBackend,
} from "../../_default/utilities/helpers";

interface Props {
  sidebarActive: boolean;
  toggleSidebar: (val: boolean) => void;
}

export default function Header({ sidebarActive, toggleSidebar }: Props) {
  const webpage_id = useAppSelector((state) => state.builder.pages.active);
  const structure = useAppSelector((state) => state.builder.structure);
  const website = useAppSelector((state) => state.builder.website);
  const activePage = useAppSelector((state) => state.builder.pages.active);

  const initialStructureRef = useRef<StructureMap | null>(null);
  const previousActivePage = useRef(activePage);
  const [dataFetched, setDataFetched] = useState(false);
  const [pageChanged, setPageChanged] = useState(false);
  const [structureInitialized, setStructureInitialized] = useState(false);

  const [loadingStructure, setIsLoadingStructure] = useState(false);

  useEffect(() => {
    if (activePage !== previousActivePage.current) {
      setPageChanged(true); // Page has changed, flag it
      setDataFetched(false);
      setStructureInitialized(false);
      initialStructureRef.current = null;
      previousActivePage.current = activePage;

      // Indicate that we're loading the structure
      setIsLoadingStructure(true);
    }
  }, [activePage]);

  useEffect(() => {
    if (
      structure &&
      structure.webpageComponents &&
      structure.webpageComponents.length > 0 &&
      Object.keys(structure.components).length > 0 &&
      !dataFetched &&
      activePage
    ) {
      StructureAPI.loadWebpageComponents(activePage)
        .then((res) => {
          initialStructureRef.current = cloneDeep(res);
        })
        .finally(() => {
          setDataFetched(true);
          setIsLoadingStructure(false);
        });

      if (pageChanged) {
        setPageChanged(false);
        setStructureInitialized(true);
      }
    }
  }, [structure, dataFetched, pageChanged]);

  const userPerson = {
    firstname: "Kasutajanimi",
    avatar: "https://d2.itprojektid.ee/assets/images/default_avatar.png",
  };

  const [saving, setSaving] = useState(0);
  const handleSave = () => {
    setSaving(1);

    if (!initialStructureRef.current || !structureInitialized) {
      NotificationManager.error("Unable to save. Please try again", null, 2000);

      const noInitialStructure = {
        name: "initialStructure fail",
        message: "",
        stack:
          "Error in Saveconfirmation.tsx, missing initialStructure.current or structureInitalized ",
        componentStack: location.pathname,
        href: window.location.href,
      };
      logErrorToBackend(noInitialStructure);

      updateEditorState({
        hasEdited: false,
      });
      setSaving(0);
      return;
    }

    const updatedComponentsArray = getUpdatedComponents(
      initialStructureRef.current,
      structure
    );

    let updatedWebpageComponentsArray: any = [];
    if (structure && structure.webpageComponents) {
      try {
        updatedWebpageComponentsArray = getUpdatedWebpageComponents(
          initialStructureRef.current.webpageComponents,
          structure.webpageComponents
        );
      } catch (error) {
        console.error("Error in getUpdatedWebpageComponents:", error);
        NotificationManager.error(
          "Error processing webpage components",
          null,
          2000
        );
        const errorUpdatedWebpageComponents = {
          name: "getUpdateWebpageComponents",
          message: error,
          stack: "Error in getUpdatedWebpageComponents Editor.tsx " + error,
          componentStack: location.pathname,
          href: window.location.href,
        };
        logErrorToBackend(errorUpdatedWebpageComponents);

        setSaving(0);
        updateEditorState({
          hasEdited: false,
        });
        return;
      }
    } else {
      console.log("No webpageComponents found in current structure");
    }

    const updatedComponents = {
      webpageComponents: updatedWebpageComponentsArray,
      components: {},
    };

    updatedComponentsArray.forEach((component) => {
      updatedComponents.components[component.id] = {
        ...component,
      };
    });

    if (
      (updatedComponentsArray && updatedComponentsArray.length > 0) ||
      updatedWebpageComponentsArray.length > 0
    ) {
      StructureAPI.updateWebpageComponents(webpage_id, updatedComponents)
        .then(() => {
          // Update the initialStructureRef
          initialStructureRef.current = cloneDeep(structure);

          updateEditorState({
            hasEdited: false,
          });
        })
        .catch((er) => {
          NotificationManager.error("An error occurred!", er, 2000);
        })
        .finally(() => setSaving(0));
    } else {
      NotificationManager.info("No changes detected.");
      setSaving(0);
      updateEditorState({
        hasEdited: false,
      });
    }
  };

  const [publishing, setPublishing] = useState(0);
  const handlePublish = () => {
    setPublishing(1);
    WebsiteApi.publishWebsite()
      .then(() => NotificationManager.success("Website published!", null, 5000))
      .catch((er) => {
        console.log(er);
        NotificationManager.error("An error occurred!", null, 5000);
      })
      .finally(() => setPublishing(0));
  };

  return (
    <nav
      className="header  navbar-expand"
      style={{
        position: "sticky",
        top: "0",
        width: "100%",
        zIndex: 0,
      }}
    >
      <div className="navbar-header"></div>
      <div className="collapse navbar-collapse h-100" id="navbar">
        <div className="col d-flex align-items-center">
          <div
            className={"home-btn" + (sidebarActive ? "" : " active")}
            onClick={() => toggleSidebar(!sidebarActive)}
          >
            <HomeIcon />
          </div>
          <label className="website-title b-ps-4 fw-bold">
            {website?.name}
          </label>
        </div>

        {/* <HeaderResponsiveOptions /> */}
        <div className="col h-100 d-flex align-items-center justify-content-end">
          <button
            className="b-btn btn-primary h-100 rounded-0 fw-bold saving-btn"
            style={{ width: "100px" }}
            onClick={() => {
              handleSave();
            }}
            disabled={saving === 1 || publishing === 1 || loadingStructure}
          >
            Save
            {saving === 1 && (
              <div
                className="loader loader-circle"
                style={{ verticalAlign: "text-top", fontSize: "inherit" }}
              ></div>
            )}
          </button>
          <button
            className="b-btn h-100 rounded-0 fw-bold publish-btn"
            style={{ width: "100px", color: "#fff" }}
            onClick={handlePublish}
            disabled={publishing === 1 || saving === 1}
          >
            Publish
            {publishing === 1 && (
              <div
                className="loader loader-circle"
                style={{ verticalAlign: "text-top", fontSize: "inherit" }}
              ></div>
            )}
          </button>
          <div className="b-ms-4 b-me-5 d-flex align-items-center user-container">
            <div className="position-relative avatar">
              {/*@ts-ignore*/}
              <img
                alt="Avatar"
                src={userPerson.avatar}
                className="b-me-4"
                style={{ height: "30px", width: "30px", borderRadius: "14px" }}
              ></img>
              <i className="avatar-online"></i>
            </div>
            {/*@ts-ignore*/}
            <label>{userPerson.firstname}</label>
          </div>
          <a
            className="b-btn btn-dark h-100 rounded-0"
            style={{ color: "#fff" }}
            href={"/websites/" + website.id}
          >
            <CloseIcon />
          </a>
        </div>
      </div>
    </nav>
  );
}
